<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Landingpage bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <LandingPageForm
                v-if="ready"
                @submit="editLandingPage"
                :enable-delete="true"
                :passedData="landingPage"
                :processing="processing"
                btn-text="Änderungen speichern"/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import LandingPageForm from "@/views/base/landingpage/LandingPageForm";

export default {
  name: 'LandingPageEdit',
  components: { LandingPageForm },
  data() {
    return {
      ready: false,
      landingPage: null,
      processing: false
    }
  },
  created() {
    axios.get('/landingPage/page/' + this.$route.params.id)
        .then(response => {
          let data = response.data
          data.unlayerJson = JSON.parse(data.unlayerJson)
          this.landingPage = data
          this.ready = true
        }).catch(err => console.error(err))
  },
  beforeRouteLeave (to, from, next) {
    if (to.params.saved) {
      next()
      return
    }
    this.$modal.showModal(
        'default',
        'Editor wirklich schließen?',
        [
          `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`
        ],
        () => {
          next()
        },
        () => {
          next(false)
        },
        { btnCancel: 'abbrechen', btnConfirm: 'Trotzdem schließen', btnConfirmColor: 'danger' }
    )
  },
  methods: {
    editLandingPage(data) {
      this.processing = true
      axios.put('/landingPage/page/' + this.$route.params.id, data)
          .then(() => {
            this.$router.push({name: 'Landingpages', params: { saved: '1' }})
            this.$toaster.makeToast('Erfolgreich!', "<b>Änderungen wurden gespeichert</b>")
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message)
            } else {
              this.$toaster.makeToast('Fehler!', err.message)
            }
          })
          .finally(() => {
            this.processing = false
          })
    }
  }
}
</script>
